
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    training: { type: Object, required: true },
    click: { type: Function, required: true },
  },
  setup(props) {
    const user = ref({ nameFirst: '', nameLast: '', avatar: '' });
    const store = useStore();

    if (props.training.user) {
      store.dispatch('API', { url: `/user/${props.training.user}`, method: 'get' }).then((res: any) => {
        user.value = res.data;
        user.value.avatar = store.getters['getAvatarUrl'](user.value.avatar);
      });
    }

    const clazz = computed(() => {
      switch (props.training.category) {
        case 'Illustration & Graphic Recording':
          return 'illu';
        case 'Inhouse':
          return 'inhouse';
        case 'Kampagnen':
          return 'kampagne';
        case 'Layout & Grafik':
          return 'layout';
        case 'Texten':
          return 'text';
        default:
          return 'sonstige';
      }
    });

    return {
      user,
      clazz,
    };
  },
});
