
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
  setup() {
    const store = useStore();
    const skills = ref<Array<Skill>>([]);
    const router = useRouter();

    store.dispatch('fetchSkills').then((res) => {
      skills.value = res
        .map((skill: any) => {
          skill.percent = Math.ceil((skill.reached / skill.max) * 100);
          return skill;
        })
        .sort((a: any, b: any) => {
          if (a.percent > b.percent) return -1;
          if (a.percent < b.percent) return 1;

          return 0;
        });
    });

    const goToSkill = (skill: string) => {
      router.push({ name: 'SkillDetails', params: { id: skill } });
    };

    return {
      skills,
      goToSkill,
    };
  },
});
