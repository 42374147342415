
import { defineComponent, inject } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const categories = store.state.trainings.categories;

    (inject('setGrey') as Function)();
    // (inject('setNoPad') as Function)();

    return {
      categories,
    };
  },
});
