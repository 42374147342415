<style lang="scss">
#trainingsindex {
  .description {
    margin-top:0 !important;
  }
  .categoryheader {
    display: flex;
    background-color: white;
    margin-left: -12px;
    margin-right: -12px;

    justify-content: center;

    .link {
      text-align: center;
      transition: 0.2s all;
      padding: 33px;
      color: #000;
      text-decoration: none;
      font-weight: bold;
      font-size: 16px;
      span {
        display:inline-block;
        padding:3px 0;
        border-bottom:2px solid #000;
      }
      &.router-link-active,
      &:hover {
        background-color: #dce3ec;
        
        span {
          border-bottom:2px solid #dce3ec;
        }
      }
    }
  }
}
</style>

<template>
  <div id="trainingsindex">
    <div class="row headline">
      <h1 class="col-12">TRAININGS</h1>
    </div>
    <div class="row description">
      <div class="col-12">
        Hier findest du einen Überblick über die stattgefundenen<br />
        Schulungen und Workshops in der Styria Media Design.
      </div>
    </div>

    <div class="categoryheader align-items-center justify-items-center">
      <router-link class="link" :to="{ name: 'TrainingsDetails', params: { id: category } }" v-for="category in categories"><span>{{ category }}</span></router-link>
    </div>

    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const categories = store.state.trainings.categories;

    (inject('setGrey') as Function)();
    // (inject('setNoPad') as Function)();

    return {
      categories,
    };
  },
});
</script>
