
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    file: { type: String, required: true },
  },
  setup(props) {
    const match = props.file.match(/.([a-zA-Z0-9]+)$/);
    const filetype = match?.length ? match[1].toLowerCase() : 'unknown';
    const clazz = computed(() => {
      switch (filetype) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'bmp':
        case 'webp':
          return 'image';
        case 'pdf':
          return 'pdf';
        case 'doc':
        case 'docx':
          return 'doc';
        case 'xls':
        case 'xlsx':
          return 'xls';
        case 'ppt':
          return 'ppt';
        case 'html':
        case 'htm':
          return 'html';
      }
    });

    const filelocation = computed(() => {
      return 'https://skillsplattform.smd-digital.at/api/trainingsfiles/' + props.file;
    });

    const filename = computed(() => {
      let filename = props.file.match(/\/([^\/]+)$/);
      return filename?.length ? filename[1] : '???';
    });

    return {
      filename,
      filelocation,
      clazz,
      filetype,
    };
  },
});
