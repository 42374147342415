<style lang="scss" scoped>
.downloadicon {
  display: inline-block;
  text-align: center;
  width: 96px;

  a {
    display: inline-block;
    text-decoration: none;
    width: 100%;
  }
  .iconcontainer {
    position: relative;
    width: 91px;
    height: 91px;
    .icon {
      position: absolute;
      width: 91px;
      height: 91px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &.image {
      background-image: none;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
    &.pdf {
      .icon {
        background-image: url(../assets/fileicons/pdf.svg);
      }
    }
    &.doc {
      .icon {
        background-image: url(../assets/fileicons/word.svg);
      }
    }
    &.ppt {
      .icon {
        background-image: url(../assets/fileicons/powerpoint.svg);
      }
    }
    &.xls {
      .icon {
        background-image: url(../assets/fileicons/excel.svg);
      }
    }
  }
  .name {
    font-size: 12px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #000;
    white-space: nowrap;
    width: 100%;
  }
}
</style>

<template>
  <div class="downloadicon download">
    <a :href="filelocation" target="_blank"
      ><div class="iconcontainer" :class="[clazz]">
        <div class="icon" v-if="clazz != 'image'"></div>
        <img :src="filelocation" v-if="clazz == 'image'" />
      </div>
      <div class="name">{{ filename }}</div>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    file: { type: String, required: true },
  },
  setup(props) {
    const match = props.file.match(/.([a-zA-Z0-9]+)$/);
    const filetype = match?.length ? match[1].toLowerCase() : 'unknown';
    const clazz = computed(() => {
      switch (filetype) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'bmp':
        case 'webp':
          return 'image';
        case 'pdf':
          return 'pdf';
        case 'doc':
        case 'docx':
          return 'doc';
        case 'xls':
        case 'xlsx':
          return 'xls';
        case 'ppt':
          return 'ppt';
        case 'html':
        case 'htm':
          return 'html';
      }
    });

    const filelocation = computed(() => {
      return 'https://skillsplattform.smd-digital.at/api/trainingsfiles/' + props.file;
    });

    const filename = computed(() => {
      let filename = props.file.match(/\/([^\/]+)$/);
      return filename?.length ? filename[1] : '???';
    });

    return {
      filename,
      filelocation,
      clazz,
      filetype,
    };
  },
});
</script>
