
import { defineComponent, ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Training from '../../../components/Training.vue';
import Download from '../../../components/Download.vue';
import { AxiosResponse } from 'axios';

export default defineComponent({
  components: {
    Training,
    Download,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const allTrainings = ref();
    const category = computed(() => router.currentRoute.value.params.id);
    const activeid = ref('');
    const activetype = ref('');
    const activeuser = ref('');

    store.dispatch('fetchTrainings').then((res: any) => {
      allTrainings.value = res;
    });

    watch(category, (newValue, oldValue) => {
      activeid.value = '';
      activetype.value = '';
      activeuser.value = '';
    });

    const trainings = computed(() => {
      return allTrainings?.value?.filter((training: Training) => training.category == category.value);
    });

    const setActive = (id: string, type: 'dl' | 'comment') => {
      activeid.value = id;
      activetype.value = type;
      if (type == 'comment' && info.value.user) {
        store.dispatch('API', { url: `/user/${info.value.user}`, method: 'get' }).then((res: any) => {
          activeuser.value = res.data;
          // @ts-ignore
          activeuser.value.avatar = store.getters['getAvatarUrl'](activeuser.value.avatar);
        });
      }
    };

    const info = computed(() => {
      if (activeid.value != '' && activetype.value != '') {
        return allTrainings?.value?.find((training: Training) => training._id == activeid.value);

        // if (activetype.value == 'dl') {
        //   return training.files;
        // }

        // if (activetype.value == 'comment') {
        //   return training.comment;
        // }
      }
      return '';
    });

    return {
      info,
      activeid,
      activetype,
      activeuser,
      trainings,
      category,
      setActive,
    };
  },
});
