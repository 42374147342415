<style lang="scss">
#skills {
  .description {
    margin-top:0 !important;
  }
  .skill {
    cursor: pointer;

    .skill-inner {
      transition: all 0.2s;

      background-color: #f9f9f9;
      padding: 15px;
     
      text-align: center;
      position: relative;
      .border-skill {
        display: block;
        height: 3px;
        background: #d9dada;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    .txt {
      position: relative;
      
      .nr {
        display: block;
        font-size: 150%;
        margin-bottom: 10px;
        font-family: 'HelveticaNeueLTPro-Hv';
      }
      .titel {
        font-family: 'HelveticaNeueLTPro-Roman';
        display: block;
      }
    }

    &:hover {
      .skill-inner {
        background-color: #f1f1f1;
        //box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
       
      }
    }
  }
  .skill-txt {
    cursor: auto;
    .txt {
      font-size: 11px;
      @media (max-width: 1500px) {
        font-size: 9px;
      }
      @media (max-width: 1200px) {
        font-size: 12px;
      }
      @media (max-width: 1200px) {
        font-size: 10px;
      }
      @media (max-width: 1200px) {
        font-size: 11px;
      }
    }
    &:hover {
      .skill-inner {
        background-color: #f9f9f9;
        box-shadow: none;
        color: #333;
      }
    }
  }
}
</style>

<template>
  <div id="skills">
    <div class="container-fluid">
      <div class="row headline">
      <h1 class="col-12">SKILL SUCHEN</h1>
    </div>
    <div class="row description justify-content-center">
      <div class="col-6">
        Diese Werte ergeben sich aus der Skills-Umfrage aus dem Jahr 2019 und werden gegebenenfalls angepasst oder verändert. Die Prozentwerte beziehen sich auf die maximal erreichbare Punktezahl in einem Skill für das gesamte Team der Mitarbeiter*innen im Bereich Grafik, Layout, Digital, Bildbearbeitung und Anzeigen.
      </div>
    </div>
      <div class="row gy-2 gx-2">
        <div @click="goToSkill(skill['_id'])" class="skill col-xs-12 col-sm-6 col-md-4 col-lg-3" :key="index" v-for="(skill, index) in skills">
          <div class="skill-inner">
            <div class="txt">
              <span class="nr">{{ skill.percent }}%</span>
              <span class="titel">{{ skill.name }}</span>
            </div>
            <div class="border-skill" :style="`width:${skill.percent}%;`"></div>
          </div>
        </div>
        <!--<div class="skill skill-txt col-xs-12 col-sm-6 col-md-12 col-lg-3">
          <div class="skill-inner">
            <div class="txt">
              Diese Werte ergeben sich aus der Skills-Umfrage aus dem Jahr 2019 und werden gegebenenfalls angepasst oder verändert. Die Prozentwerte beziehen sich auf die maximal erreichbare Punktezahl in einem Skill für das gesamte Team der Mitarbeiter*innen im Bereich Grafik, Layout, Digital, Bildbearbeitung und Anzeigen.
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
  setup() {
    const store = useStore();
    const skills = ref<Array<Skill>>([]);
    const router = useRouter();

    store.dispatch('fetchSkills').then((res) => {
      skills.value = res
        .map((skill: any) => {
          skill.percent = Math.ceil((skill.reached / skill.max) * 100);
          return skill;
        })
        .sort((a: any, b: any) => {
          if (a.percent > b.percent) return -1;
          if (a.percent < b.percent) return 1;

          return 0;
        });
    });

    const goToSkill = (skill: string) => {
      router.push({ name: 'SkillDetails', params: { id: skill } });
    };

    return {
      skills,
      goToSkill,
    };
  },
});
</script>
