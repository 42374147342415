<style lang="scss">
.box {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  background-color: #fff;

  &.comment {
    text-align: center;

    p {
      margin: 0;
    }

    p.text {
      margin-bottom: 10px;
    }

    .person {
      border-bottom: 3px solid #eaeaea;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      margin-left: -10px;
      margin-right: -10px;
      .avatar {
        margin: 0 auto;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
      }

      .name {
        font-size: 17px;
      }
    }
  }

  .download {
    margin-right: 5px;
  }
}

.box-header {
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: 'HelveticaNeueLTPro-Bd';

  &.comment {
    text-align: center;
  }
}
</style>

<template>
  <div class="row">
    <div class="col-2"></div>
    <div class="col-5 trainings">
      <div class="row">
        <Training class="col-5" :class="{ active: training._id == activeid }" :click="setActive" :training="training" :key="training" v-for="(training, index) in trainings" />
      </div>
    </div>
    <div class="col-4" v-if="activetype == 'dl'">
      <h3 class="box-header">Downloads</h3>
      <div class="box">
        <Download :file="file" v-for="file in info.files" />
      </div>
    </div>
    <div class="col-4" v-if="activetype == 'comment' && info.comment[0]">
      <h3 class="box-header comment">{{ info.name }}</h3>
      <div class="box comment">
        <div class="person" v-if="activeuser != ''">
          <div class="avatar" :style="`background-image: url(${activeuser.avatar})`"></div>
          <div class="name">{{ activeuser.nameFirst }} {{ activeuser.nameLast }}</div>
        </div>
        <p><b>Ziel des Trainings</b></p>
        <p class="text">{{ info.comment[0].target }}</p>
        <p><b>Keylearnings</b></p>
        <p class="text">{{ info.comment[0].learnings }}</p>
        <p><b>Relevanz für den Arbeitsalltag</b></p>
        <p class="text">{{ info.comment[0].relevance }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Training from '../../../components/Training.vue';
import Download from '../../../components/Download.vue';
import { AxiosResponse } from 'axios';

export default defineComponent({
  components: {
    Training,
    Download,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const allTrainings = ref();
    const category = computed(() => router.currentRoute.value.params.id);
    const activeid = ref('');
    const activetype = ref('');
    const activeuser = ref('');

    store.dispatch('fetchTrainings').then((res: any) => {
      allTrainings.value = res;
    });

    watch(category, (newValue, oldValue) => {
      activeid.value = '';
      activetype.value = '';
      activeuser.value = '';
    });

    const trainings = computed(() => {
      return allTrainings?.value?.filter((training: Training) => training.category == category.value);
    });

    const setActive = (id: string, type: 'dl' | 'comment') => {
      activeid.value = id;
      activetype.value = type;
      if (type == 'comment' && info.value.user) {
        store.dispatch('API', { url: `/user/${info.value.user}`, method: 'get' }).then((res: any) => {
          activeuser.value = res.data;
          // @ts-ignore
          activeuser.value.avatar = store.getters['getAvatarUrl'](activeuser.value.avatar);
        });
      }
    };

    const info = computed(() => {
      if (activeid.value != '' && activetype.value != '') {
        return allTrainings?.value?.find((training: Training) => training._id == activeid.value);

        // if (activetype.value == 'dl') {
        //   return training.files;
        // }

        // if (activetype.value == 'comment') {
        //   return training.comment;
        // }
      }
      return '';
    });

    return {
      info,
      activeid,
      activetype,
      activeuser,
      trainings,
      category,
      setActive,
    };
  },
});
</script>
