<style lang="scss" scoped>
$outerpadding: 30px;
$innerpadding: 15px;
.trainingcontainer {
  padding: $outerpadding;

  &.active {
    .training {
      box-shadow: 0 0 2px rgba(255, 0, 0, 1);
    }
  }

  .training {
    background-color: #fff;
    transition: 0.2s all;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    text-align: center;
    height: 100%;
    .picture {
      height: 100px;
      background-color: grey;
      background-position: center;
      background-size: cover;

      &.illu {
        background-image: url(../assets/trainingscategories/praensentation.png);
      }

      &.inhouse {
        background-image: url(../assets/trainingscategories/inhouse.png);
      }
      &.kampagne {
        background-image: url(../assets/trainingscategories/kampagnen.png);
      }
      &.layout {
        background-image: url(../assets/trainingscategories/layout.png);
      }
      &.text {
        background-image: url(../assets/trainingscategories/texten.png);
      }
      &.sonstige {
        background-image: url(../assets/trainingscategories/sonstige.png);
      }
    }

    .person {
      .avatar {
        height: 60px;
        width: 60px;
        border-radius: 50%;

        display: inline-block;
        margin-top: -30px;
        background-position: center;
        background-size: contain;
      }

      .name {
        margin-top: 4px;
      }
    }

    .title {
      font-family: 'HelveticaNeueLTPro-Bd';
      font-size: 17px;
      margin-top: 30px;
      margin-bottom: 30px;
      padding-left: $innerpadding;
      padding-right: $innerpadding;
    }

    .buttons {
      padding: $innerpadding;

      .button {
        cursor: pointer;
        width: 110px;
        padding: 3px;
        margin: 0 auto;
        background-color: #ebebeb;
        border-radius: 20px;
        transition: 0.2s all;
        & + .button {
          margin-top: 18px;
        }

        &:hover {
          background-color: #a8a8a8;
          color: #fff;
        }
      }
    }

    padding-bottom: 18px;
  }
}
</style>

<template>
  <div class="trainingcontainer">
    <div class="training">
      <div class="picture" :class="[clazz]"></div>
      <div class="person" v-if="training.user">
        <div class="avatar" :style="`background-image: url(${user.avatar})`"></div>
        <div class="name">{{ user.nameFirst }} {{ user.nameLast }}</div>
      </div>
      <div class="title">
        {{ training.name }}
      </div>
      <div class="buttons">
        <div class="button" @click="click(training._id, 'comment')" v-if="training.comment?.length > 0">Feedback</div>
        <div class="button" @click="click(training._id, 'dl')" v-if="training.files?.length > 0">Download</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    training: { type: Object, required: true },
    click: { type: Function, required: true },
  },
  setup(props) {
    const user = ref({ nameFirst: '', nameLast: '', avatar: '' });
    const store = useStore();

    if (props.training.user) {
      store.dispatch('API', { url: `/user/${props.training.user}`, method: 'get' }).then((res: any) => {
        user.value = res.data;
        user.value.avatar = store.getters['getAvatarUrl'](user.value.avatar);
      });
    }

    const clazz = computed(() => {
      switch (props.training.category) {
        case 'Illustration & Graphic Recording':
          return 'illu';
        case 'Inhouse':
          return 'inhouse';
        case 'Kampagnen':
          return 'kampagne';
        case 'Layout & Grafik':
          return 'layout';
        case 'Texten':
          return 'text';
        default:
          return 'sonstige';
      }
    });

    return {
      user,
      clazz,
    };
  },
});
</script>
